import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import CSSPlugin from 'gsap/CSSPlugin';
gsap.registerPlugin(ScrollTrigger, CSSPlugin);

/*
const heroSlide = function () {
	const slideItem = document.querySelectorAll('.hero__bg__item');
	let slideMax = slideItem.length;
	let prevNum = 0;
	let slideNum = 1;
	let nextNum = 2;
	slideItem[0].classList.add('active');
	setInterval(() => {
		slideItem.forEach(entry => {
			entry.classList.remove('active');
			entry.classList.remove('next');
			entry.classList.remove('prev');
		});

		//
		if (slideNum == slideMax) {
			slideNum = 0;
		}

		//
		if (prevNum == slideMax) {
			prevNum = 0;
		}

		//
		if (nextNum == slideMax) {
			nextNum = 0;
		}

		//
		slideItem[prevNum].classList.add('prev');
		slideItem[nextNum].classList.add('next');
		slideItem[slideNum].classList.add('active');
		slideNum++;
		prevNum++;
		nextNum++;
	}, 4500);
}*/


const info__cont__h = document.querySelector('.intro__cont__left').offsetHeight;
const introscrolling = function () {
	const tl = gsap.timeline({
		scrollTrigger: {
			trigger: ".intro",
			scrub: true,
			start: "top-=400px top",
			end: "bottom-=" + info__cont__h + "px bottom",
			pin: false,
			//pinSpacing: false,
			//markers: true,
		},
	});

	/*tl.fromTo(
		".intro__flowText__inner",
		{
			xPercent: '-10',
			ease: "ease.out",
		},
		{
			xPercent: '-60',
			ease: "ease.out",
		},

	);*/
	tl.fromTo(
		".intro__bg__pic img",
		{
			scale: 0,
			ease: "ease.out",
		},
		{
			scale: 1,
			ease: "ease.out",
		}, '<'

	);

	const tl2 = gsap.timeline({
		scrollTrigger: {
			trigger: ".intro__cont__inner",
			scrub: true,
			start: "top bottom",
			end: "top+=500px bottom",
			//markers: true,
		},
	});
	tl2.fromTo(
		".intro__bg__overlay",
		{
			autoAlpha: 0,
			ease: "ease.out",
		},
		{
			autoAlpha: 1,
			ease: "ease.out",
		}, '<'

	);
	const tl3 = gsap.timeline({
		scrollTrigger: {
			trigger: ".intro__cont__inner",
			scrub: true,
			start: "top-=300px bottom",
			end: "top+=100px bottom",
			//markers: true,
		},
	});
	tl3.fromTo(
		".intro__flowText__inner",
		{
			autoAlpha: 1,
			ease: "ease.out",
		},
		{
			autoAlpha: 0,
			ease: "ease.out",
		}, '<'

	);


	const tl32 = gsap.timeline({
		scrollTrigger: {
			trigger: ".intro__cont__right",
			scrub: true,
			start: "top bottom",
			end: "bottom top",

		},
	});
	tl32.fromTo(
		".intro__cont__right img",
		{
			scale: 1,
			ease: "ease.out",
		},
		{
			scale: 1.5,
			ease: "ease.out",
		}, '<'

	);
}
const newsScrolling = function () {
	/*const tl4 = gsap.timeline({
		scrollTrigger: {
			trigger: ".news",
			scrub: true,
			pinSpacing: false,
			start: "top bottom",
			end: "bottom top",
			//markers:true,
		},
	});
	tl4.fromTo(
		".news .site__flowText__inner",
		{
			xPercent: '-10',
			ease: "ease.out",
		},
		{
			xPercent: '-60',
			ease: "ease.out",
		},

	);*/

	const flowText__trigger = document.querySelectorAll('.js--flowText__trigger');
	const flowTexts = document.querySelectorAll('.js--flowText__inner__inner');

	flowTexts.forEach((flowText, index) => {
		const tl_ft = gsap.timeline({
			repeat: -1,

			scrollTrigger: {
				trigger: flowText__trigger[index],
				//scrub: false,
				pinSpacing: false,
				start: "top bottom",
				end: "bottom top",
				//markers:true,
				toggleActions: 'play pause resume pause',
			},
		});
		tl_ft.fromTo(
			flowText,
			{
				xPercent: '0',
				ease: "linear",
			},
			{
				xPercent: '-50',
				ease: "linear",
				duration: 18,
			},

		);
	});

	const tl4 = gsap.timeline({
		scrollTrigger: {
			trigger: ".site__parallax__image",
			scrub: true,
			start: "top bottom",
			end: "bottom top",

		},
	});
	tl4.fromTo(
		".site__parallax__image img",
		{
			scale: 1,
			ease: "ease.out",
		},
		{
			scale: 1.2,
			ease: "ease.out",
		}, '<'

	);
}

const companyScrolling = function () {



	let cards = gsap.utils.toArray(".js__card");

	let stickDistance = '57';

	let h1 = document.querySelector('.company__title').offsetHeight;

	ScrollTrigger.create({
		trigger: '.company__inner__bg',
		endTrigger: '.company__card3',
		start: "top top",
		//end: () => lastCardST.start + stickDistance,
		end: "top-=" + (h1 + (stickDistance * 2)) + "px top",
		pin: true,
		pinSpacing: false,
		ease: "none",
		toggleActions: "restart none none reverse"
	});
	ScrollTrigger.create({
		trigger: '.company__title',
		endTrigger: '.company__card3',
		start: "top top",
		//end: () => lastCardST.start,
		//end: () => lastCardST.start + stickDistance,
		end: "top-=" + (h1 + (stickDistance * 2)) + "px top",
		pin: true,
		pinSpacing: false,
		ease: "none",
		toggleActions: "restart none none reverse",
	});

	cards.forEach((card, index) => {
		ScrollTrigger.create({
			trigger: card,
			endTrigger: '.company__card3',
			start: "top-=" + (h1 + (stickDistance * index)) + "px top",
			//start: "center center",
			//end: () => lastCardST.start + stickDistance,
			//end: "top-=" + (h1 + (stickDistance * index)) + "px top",
			end: "top-=" + (h1 + (stickDistance * 2)) + "px top",
			pin: true,
			pinSpacing: false,
			ease: "none",
			toggleActions: "restart none none reverse",
			//markers: true,
		});
	});
}
window.addEventListener("pageshow", function () {

	//
	const nbtns = document.querySelectorAll('.js-tab__btn');
	const nmenu_targets = document.querySelectorAll('.js-tab__body');
	const nmenu_container = document.querySelector('.news__postList');




	//let state = false;
	nbtns.forEach((btn, index) => {
		btn.addEventListener("click", function () {
			let nmenu__base__h = nmenu_targets[index].offsetHeight;
			nmenu_container.style.minHeight = nmenu__base__h + 'px';
			if (this.getAttribute('aria-expanded') == 'false') {
				const tabAnim = gsap.timeline();
				tabAnim.fromTo(
					".js-tab__body",
					{
						xPercent: 0,
						autoAlpha: 1,
					},
					{
						xPercent: -5,
						autoAlpha: 0,
						duration: .2,
						ease: "power3.in",
						onComplete: () => {
							nmenu_targets.forEach((box, index) => {
								box.style.display = 'none'
							});
						}
					},

				);
				tabAnim.set(
					nmenu_targets[index],
					{
						display: "block",
					}
				);
				tabAnim.fromTo(
					nmenu_targets[index],
					{
						xPercent: 5,
						autoAlpha: 0,
					},
					{
						xPercent: 0,
						autoAlpha: 1,
						duration: .2,
						ease: "power3.out",
					},

				);

				//
				nbtns.forEach((btn, index) => {
					btn.setAttribute('aria-expanded', 'false');
				});
				this.setAttribute('aria-expanded', 'true');

				//
				nmenu_targets.forEach((menu_target, index) => {
					menu_target.setAttribute('aria-hidden', 'true');
				});
				nmenu_targets[index].setAttribute('aria-hidden', 'false');
			}
		});
	});



	//heroSlide();
	introscrolling();
	newsScrolling();
	companyScrolling();


	ScrollTrigger.refresh;

});
